import { reactive } from '@nuxtjs/composition-api'

export default function usePath() {
  const path = reactive({
    receipt: '/receipt/',
    receiptQr: '/receipt/qr/',
    receiptQrRead: '/receipt/qr/read/',
    receiptRegister: '/receipt/register/',
    receiptRegisterByQr: '/receipt/register/?isQrCodeReading=true',
    receiptEnquete: '/receipt/enquete/',
    receiptConfirm: '/receipt/confirm/',
    receiptComplete: '/receipt/complete/',
    mypage: '/mypage/',
    mypageEdit: '/mypage/edit/',
    mypageVehicles: '/mypage/vehicles/',
    mypageVehiclesId: '/mypage/vehicles/:id/',
    mypageQuotations: '/mypage/quotations/',
    mypageQuotationsId: '/mypage/quotations/:id/',
    mypagePurchases: '/mypage/purchases/',
    mypagePurchasesId: '/mypage/purchases/:id/',
    vehiclesRegistration: '/vehicles/registration/',
    vehiclesRegistrationSelectType: '/vehicles/registration/select-type/',
    vehiclesRegistrationQr: '/vehicles/registration/qr/',
    vehiclesRegistrationConfirm: '/vehicles/registration/confirm/',
    vehiclesRegistrationComplete: '/vehicles/registration/complete/',
    quotations: '/quotations/',
    quotationsDetails: '/quotations/details/',
    quotationsComplete: '/quotations/complete/',
    pitreservation: '/pit-reservation/',
    tireRepair: '/tire-repair/',
    tireGuarantee: '/tire-guarantee/',
    deliveryEnquete: '/delivery-enquete/',
    deliveryEnqueteComplete: '/delivery-enquete/complete/',
    inflow: '/inflow/',
    terms: '/terms/',
    beelineShopSearch: 'https://beeline-tire.co.jp/shop_search/',
    outOfArea: '/out-of-area/',
    notPitReservations: '/not-pit-reservations/',
    underConstruction: '/under-construction/',
    unfollowAccount: '/unfollow-account/',
    location: '/location/',
    integrateUserError: '/integrate-user-error/',
  })

  return { path }
}
